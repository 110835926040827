/* Component Dependencies */
var programLogoTemplate = require('templates/programLogo.hbs');
var AriesComponent = require('libs/aries-component');
var PubSub = require('libs/pub-sub');

PubSub.register(AriesComponent.extend({
  type: 'programLogo',
  template: {
    'programLogo': programLogoTemplate
  }
}));
